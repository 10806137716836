import Box from '../../../common/src/components/Box';
import { ChapterDescription, ContentWrapper, ImageWrapper, MainHeading } from './BookIntroduction.style';
import Image from '../../../common/src/components/Image';
import React from 'react';
import PropTypes from 'prop-types';

const BookIntroduction = ({ row, col, chapterHeadingText, chapterImage }) => {
  return (
    <Box className="row" {...row}>
      <Box className="col" {...col}>
        <ContentWrapper>
          <MainHeading>9 KOMENTÁROV ku <span>komunistickej strane</span></MainHeading>
          <ChapterDescription>
            Kniha 9 komentárov ku komunistickej strane ponúka čitateľovi pohľad na necenzurovanú históriu Čínskej
            komunistickej strany. Vďaka svojmu pravdivému vykresleniu brutality a podvodov komunistickej strany si 9
            komentárov okamžite získalo pozornosť ľudí. Mnohí z nich začali spontánne vystupovať z Čínskej komunistickej
            strany a stali sa tak masívnou silou pokojnej transformácie v Číne.
          </ChapterDescription>
          <ChapterDescription>
            9 komentárov sa zároveň pokúša vyniesť historický rozsudok nad medzinárodným komunistickým hnutím, ktoré v
            posledných sto rokoch neprinieslo ľudstvu nič než skazu.
          </ChapterDescription>
          <ChapterDescription>
            V súčasnosti je kniha preložená do 33 jazykov. Ako čitatelia na Slovensku ju dostávame k dispozícii s
            vierou, že ak porozumieme skutočnej histórii Čínskej komunistickej strany, môžeme pomôcť predísť takýmto
            tragédiám, aby sa už nikdy v budúcnosti nezopakovali.
          </ChapterDescription>
        </ContentWrapper>
      </Box>
      <Box className="col" {...col}>
        <ImageWrapper>
          <Image src={chapterImage} alt='obrazok knihy'/>
        </ImageWrapper>
      </Box>
    </Box>
  );
};

// BookIntroduction style props
BookIntroduction.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object
};

// BookIntroduction default style
BookIntroduction.defaultProps = {
  // BookIntroduction row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px'
  },
  // BookIntroduction col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px'
  }
};

export default BookIntroduction;