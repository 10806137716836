import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../../common/src/components/Box';
import Container from '../../../common/src/components/UI/Container';
import SectionWrapper, {
  ContentArea,
  Heading,
  TextQuotation,
  TextQuotationAuthor
} from './CitationSection.style';

const CitationSection = ({ row, col, colFull, title }) => {
  return (

        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ContentArea>
              <Heading>
                Čo o knihe hovoria iní
              </Heading>
              <TextQuotation>
                V podstate každý v Číne čítal alebo prinajmenšom počul o Deviatich komentároch

              <cite>
                Tchang Paj-čchiao, jeden z vodcov študentov počas hnutia na Námestí nebeského pokoja v roku 1989
              </cite>
              </TextQuotation>

              <TextQuotation>
                Čínska komunistická strana sa neodvažuje otvorene hovoriť o faktoch spomínaných v Deviatich komentároch,
                ani sa neodvažuje odmietať Deväť komentárov... ČKS nespomína Deväť komentárov v žiadnom zo svojich
                vzdelávacích materiálov používaných na školách strany, tváriac sa, že neexistujú... ČKS sa veľmi bojí.

                <cite>Čeng En-čchung, právnik zo Šanghaja pôsobiaci v oblasti ľudských práv</cite>
              </TextQuotation>

              <TextQuotation>
                Hnutie Tuidang je v skutočnosti hnutím ‚obnovy morálky‘, ktorá je základom pre obnovu čínskeho národa.

              <cite>
                Wei Jingsheng, známy čínsky aktivista v oblasti ľudských práv, „otec čínskej demokracie“</cite>
              </TextQuotation>

            </ContentArea>
          </Box>
        </Box>

  );
};

// CitationSection style props
CitationSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textStyle: PropTypes.object
};

// CitationSection default style
CitationSection.defaultProps = {
  // CitationSection row default style
  row: {
    flexBox: true,
    alignItems: 'center',
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px'
  },
  // CitationSection col default style
  col: {
    width: ['100%', '100%', '100%'],
    pl: '15px',
    pr: '15px',
    mb: '15px'
  },
  // CitationSection col default style
  colFull: {
    width: ['100%', '100%', '100%'],
    pl: '15px',
    pr: '15px',
    mb: '30px'
  }
};

export default CitationSection;
