import React, { useContext } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
//import AnchorLink from 'react-anchor-link-smooth-scroll';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Image from './../../../common/src/components/Image';
import Container from './../../../common/src/components/UI/Container';
import NavbarWrapper from './navbar.style';
import logoImage from './../../../common/src/assets/image/charity/9k/9K_logo_top.png';
import altLogoImage from './../../../common/src/assets/image/charity/specter/bez-komumizmu-logo-inv.png';
import { DrawerContext } from './../../../common/src/contexts/DrawerContext';
import Box from './../../../common/src/components/Box';
import ScrollSpyMenu from './../../../common/src/components/ScrollSpyMenu';
import Drawer from './../../../common/src/components/Drawer';
import HamburgMenu from './../../../common/src/components/HamburgMenu';
import { themeGet } from 'styled-system';

const Navbar = ({ navbarStyle, logoStyle, row, menuWrapper }) => {
  const Data = useStaticQuery(graphql`
    query {
      charityJson {
        menuItems {
          path
          label
        }
      }
    }
  `);
  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  const menuItems = [
    {
      "label": "Úvod",
      "path": "/#uvod"
    },
    {
      "label": "Prečítajte si knihu",
      "path": "/#read"
    },
    {
      "label": "Iné jazyky",
      "path": "/#paticka"
    }

  ]

  return (
    <NavbarWrapper {...navbarStyle}>
      <Container width="1260px">
        <Box {...row}>
          <AnchorLink to="/" offset={0} className="logo">
            <Image src={logoImage} alt="9 komentarov" />
          </AnchorLink>
          <AnchorLink to="/" offset={0} className="alt-logo">
            <Image src={altLogoImage} alt="9 komentarov" />
          </AnchorLink>
          <Box {...menuWrapper}>
            <ScrollSpyMenu
              className="main_menu"
              menuItems={menuItems}
              items={[
                'uvod',
                'history',
                'reakcie',
                'blog',
                'distancovat',
                'none',
              ]}
              offset={-80}
            />
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={
                <HamburgMenu
                  barColor={themeGet('colors.distanceActionBack', '#07204a')}
                />
              }
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={menuItems}
                drawerClose={true}
                offset={-100}
              />
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.defaultProps = {
  navbarStyle: {
    className: 'navbar',
    minHeight: '70px',
    display: 'block',
  },
  row: {
    flexBox: true,
    alignItems: 'center',
    justifyContent: [
      'space-between',
      'space-between',
      'space-between',
      'space-between',
    ],
    width: '100%',
  },
  logoStyle: {
    maxWidth: '130px',
    mr: [0, 0, 0, '166px'],
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'primaryWithBg',
    minHeight: 'auto',
    height: `${1}`,
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center',
  },
};

export default Navbar;
