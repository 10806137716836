import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Box from '../../../common/src/components/Box';
import Text from '../../../common/src/components/Text';
import Heading from '../../../common/src/components/Heading';
import Container from '../../../common/src/components/UI/Container';
import FooterWrapper, {
  List,
  ListItem,
  CopyrightText,
} from './footer.style';
import BlockWrapper from '../BookIntroduction/BookIntroduction.style';

const Footer = ({ row, col, colOne, colTwo }) => {

  return (
    <FooterWrapper  id="paticka">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <CopyrightText>
              <Text className="text" content="© COPYRIGHT THE EPOCH TIMES" />
            </CopyrightText>
          </Box>
          <Box className="col" {...col}>
            <Heading
              className="widget_title"
              as="h3"
              content="Kniha iných jazykoch"
            />
            <List>
              <ListItem>
                <a href="http://www.ninecommentaries.com/">
                  www.ninecommentaries.com
                </a>
              </ListItem>
            </List>
          </Box>
          <Box className="col" {...col}>
            <Heading
              className="widget_title"
              as="h3"
              content="Globálne centrum pre výstup z ČKS"
            />
            <List>
              <ListItem>
                <a href="https://global.tuidang.org/">
                  global.tuidang.org
                </a>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },

  // Footer col default style
  col: {
    width: ['100%', '100%', '33%', '33%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};

export default Footer;
