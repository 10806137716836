module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-155},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/9_komentarov_web-512x512.jpg","icons":[{"src":"icon/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"icon/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"icon/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"icon/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"icon/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"icon/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"icon/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"icon/icon-512x512.png","sizes":"512x512","type":"image/png"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
