const colors = {
  transparent: 'transparent',
  lightGray: '#F0F0F0',
  primary: '#478595',
  primaryHover: '#ECF22F',
  primary2: '#f7e49f',
  secondary: '#ff0000',
  secondary2: '#7d313f',
  secondaryHover: '#ff0000',
  yellow: '#ab891d',
  yellowHover: '#d6b038',
  border: '#dadada',
  black: '#000000',
  white: '#ffffff',
  heading: '#060F1E',
  text: '#294859',
  secondaryText: '#616970',
  lightText: '#929292',
  linkedin: '#0077B5',
  facebook: '#3b5998',
  twitter: '#38A1F3',
  github: '#211f1f',
  select: '#525F7F',
  headingLink: '#ff4e24',
  distanceActionBack: '#08080a',
  footerBackground: '#efefef',
};

export default colors;
