import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { charityTheme } from './../common/src/theme/charity';
import { ResetCSS } from './../common/src/assets/css/style';
import { DrawerProvider } from './../common/src/contexts/DrawerContext';
import Navbar from '../containers/Charity/Navbar';
import CitationSection from '../containers/Charity/CitationSection';
import Footer from '../containers/Charity/Footer';
import "@fontsource/lora/400.css"
import "@fontsource/lora/500.css"
import "@fontsource/lora/600.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/oswald/500.css"

import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapperOtherPages
} from '../containers/Charity/charity.style';
import SEO from '../components/seo';
import imageGeneral from '../common/src/assets/image/charity/9k/9_komentarov_obalka-1200x746_sk.jpg';
import Container from '../common/src/components/UI/Container';
import BookIntroduction from '../containers/Charity/BookIntroduction/BookIntroduction';
import imageBook from '../common/src/assets/image/charity/9k/9k_book_mockup.jpg';
import BlockWrapper from '../containers/Charity/BookIntroduction/BookIntroduction.style';
import ReadBookOptions from '../containers/Charity/ReadBookOptions/ReadBookOptions';

export default () => {
  return (
    <ThemeProvider theme={charityTheme}>
      <Fragment>
        <SEO title="9 komentárov ku komunistickej strane"
             description="Kniha, ktorá inšpirovala milióny ľudí k vystúpeniu z Čínskej komunistickej strany"
             image={imageGeneral}/>
        <ResetCSS/>
        <GlobalStyle/>
        {/* Start charity wrapper section */}
        <CharityWrapper>
          <ContentWrapperOtherPages>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar/>
              </DrawerProvider>
            </Sticky>
            <BlockWrapper id="uvod" topMargin={87}>
              <Container width="1260px">
                <BookIntroduction
                  chapterImage={imageBook}
                />
              </Container>
            </BlockWrapper>
            <BlockWrapper id="read" topMargin={20}>
              <Container width="1260px">
                <ReadBookOptions/>
                <CitationSection/>
              </Container>
            </BlockWrapper>
          </ContentWrapperOtherPages>
          <Footer/>
        </CharityWrapper>
        {/* End of charity wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};
