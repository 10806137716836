import Box from '../../../common/src/components/Box';
import { ChapterDescription, ContentWrapper, ButtonBook, ButtonColWrapper, MainHeading } from './ReadBookOptions.style';
import Image from '../../../common/src/components/Image';
import React from 'react';
import PropTypes from 'prop-types';


const ReadBookOptions = ({ row, col, chapterHeadingText, chapterImage }) => {
  return (
    <Box className="row" {...row}>
      <Box className="col" {...col}>
        <ContentWrapper>
          <MainHeading>Prečítajte si knihu 9 komentárov ku komunistickej strane</MainHeading>
          <ChapterDescription>
            <ButtonColWrapper>
              <a href="9k/9_komentarov_ku_komunistickej_strane.epub">
                <ButtonBook>
                  Stiahnuť si elektronickú knihu (*.epub)
                </ButtonBook>
              </a>
            </ButtonColWrapper>
            <ButtonColWrapper>
              <a href="http://www.ninecommentaries.com/s/9_komentarov_sk_20170704-g77t.pdf" target={'_blank'}>
              <ButtonBook>
                Čítať online (*.pdf)
              </ButtonBook>
              </a>
            </ButtonColWrapper>
            <ButtonColWrapper>
              <a href="https://www.martinus.sk/?uItem=281709"  target={'_blank'}>
              <ButtonBook>
                Zakúpiť v kníhkupectve
              </ButtonBook>
              </a>
            </ButtonColWrapper>
          </ChapterDescription>

        </ContentWrapper>
      </Box>
    </Box>
  );
};

// BookIntroduction style props
ReadBookOptions.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object
};

// BookIntroduction default style
ReadBookOptions.defaultProps = {
  // BookIntroduction row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px'
  },
  // BookIntroduction col default style
  col: {
    width: ['100%', '100%', '100%'],
    pl: '15px',
    pr: '15px',
    mb: '30px'
  }
};

export default ReadBookOptions;