import styled from 'styled-components';
import { themeGet } from 'styled-system';

const BlockWrapper = styled.div`
  width: 100%;
  margin-top: ${({ topMargin = 0 }) => topMargin}px;
  @media only screen and (max-width: 1440px) {
  }
  @media only screen and (max-width: 1360px) {
  }
  @media only screen and (max-width: 991px) {
  }

  .row {
    align-items: center;
    .col {
      @media only screen and (max-width: 480px) {
        &:first-child {
          order: 2;
          margin-top: 15px;
        }
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  padding-right: 50px;
  @media only screen and (max-width: 1440px) {
    padding-right: 40px;
  }
  @media only screen and (max-width: 991px) {
    padding-right: 0;
  }

  h5 {
    font-size: 16px;
    line-height: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${themeGet('colors.secondary2', '#EE2541')};
    margin-bottom: 30px;
    @media only screen and (max-width: 1440px) {
      margin-bottom: 27px;
    }
    @media only screen and (max-width: 1360px) {
      font-size: 14px;
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 13px;
      margin-bottom: 15px;
    }
  }

  h2 {
    font-size: 30px;
    line-height: 38px;
    font-weight: 700;
    @media only screen and (max-width: 1360px) {
      font-size: 28px;
      line-height: 35px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 26px;
      line-height: 34px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 30px;
    }
  }
  a.learn__more-btn {
    float: right;
  }

`;

export const List = styled.ul`
  margin-bottom: 50px;
  padding-top: 5px;
  @media only screen and (max-width: 1440px) {
    padding-top: 0;
    margin-bottom: 45px;
  }
  @media only screen and (max-width: 1360px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 991px) {
    margin-bottom: 30px;
  }
`;

export const Item = styled.li`
  font-size: 20px;
  line-height: 40px;
  padding: 10px 0;
  color: ${themeGet('colors.heading', '#060F1E')};
  @media only screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 30px;
  }
  @media only screen and (max-width: 1360px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media only screen and (max-width: 991px) {
    font-size: 15px;
    line-height: 20px;
  }
`;

export const ParagraphHeading = styled.h3`
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 1.4em;
    span {
      color: ${themeGet('colors.secondaryHover', '#FF282F')}; 
    }
`;

export const SubHeading = styled.h4`
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: bold;
    span {
      color: ${themeGet('colors.secondaryHover', '#FF282F')}; 
    }
`;
export const MainHeading = styled.h2`
    font-size: 30px;
    line-height: 46px;
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 70px;
    font-weight: 700;
    font-family: 'Oswald', sans-serif;
    margin: 10px 0 20px; 
    color: ${themeGet('colors.heading', '#000000')};
    span {
      color: ${themeGet('colors.secondary2', '#710a22')}; 
      font-weight: 700;
      font-family: 'Oswald', sans-serif;
    }
    @media only screen and (max-width: 1200px) {
    margin: 30px 0 20px; 
    }
    @media only screen and (max-width: 767px) {
    font-size: 26px;
    }
`;
export const ChapterDescription = styled.p`
    font-size: 18px;
    line-height: 26px;
    font-family: 'Roboto',serif;
    span {
      color: ${themeGet('colors.secondaryHover', '#FF282F')}; 
    }
    @media only screen and (max-width: 1440px) {
    }
    @media only screen and (max-width: 1360px) {
    }
    @media only screen and (max-width: 991px) {
    }
    @media only screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
    }
`;

export const ImageWrapper = styled.div`
  padding-left: 37px;
  @media only screen and (max-width: 1360px) {
    padding-left: 30px;
  }
  @media only screen and (max-width: 991px) {
    padding-left: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;

export default BlockWrapper;
